import WeView                                       from "../../component/basecomponent/WeView";
import { css, html, LitElement, noChange, nothing } from "lit";
import { repeat }                                   from 'lit/directives/repeat.js';
import { choose }                                   from 'lit/directives/choose.js';

import './fields/FieldGeo';
import './fields/FieldDate';
import '@vaadin/vertical-layout';
import '@vaadin/horizontal-layout';
import '@vaadin/button';
import '@vaadin/form-layout';
import '@vaadin/text-field';
import '@vaadin/text-area';
import '@vaadin/tabs';
import { Notification }                             from '@vaadin/notification';
import { formatISO }                                from "date-fns";


export default class FormPage extends LitElement {

    static styles = css`
      root: {
        font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif
      };

      .subtitle {
        padding-top: var(--lumo-space-s);
        color: var(--lumo-contrast-50pct);
      };

      vaadin-text-area {
        min-height: 180px;
        height: 100%;
      };

      .header {
        display: flex;
        width: 100%;
        flex-direction: column;
        background-color: var(--lumo-contrast-5pct);
        padding: var(--lumo-space-l);
        padding-bottom: var(--lumo-space-s);
        box-sizing: border-box;
        align-items: flex-start;
      };

      @media screen and(min-width: 900px) {

        .keep {
          max-width: 800px;
        }

        .content {
          margin-top: 3em;
        }

      }

      @media screen and (max-width: 640px) {
        
        .buttons {
          width: 100%;
          padding-left: var(--lumo-space-l);
          padding-right: var(--lumo-space-l);
        }

        .action-button {
          width: 100%;

        }

        .content {
          margin-top: 1.5em;
        }

      }

      @media screen and (min-width: 1050px) {
        .buttons {

        }

        .content {
          margin-top: 8em;
        }
      }

      .section {
        margin-top: var(--lumo-space-s);
        padding-left: var(--lumo-space-l);
        padding-right: var(--lumo-space-l);
      }

      .items {
        margin-top: var(--lumo-space-s);
        padding-left: var(--lumo-space-l);
        padding-right: var(--lumo-space-l);
      }

      .form {
        width: 100%;
      }

      .content {
        color: var(--lumo-contrast-60pct);
        padding-left: var(--lumo-space-l);
        padding-right: var(--lumo-space-l);
        border-color: var(--lumo-border-contrast);
        border-radius: var(--lumo-border-radius-l) var(--lumo-border-radius-l) 0 0;
      }

      .wave-is-hidden {
        display: none;
      }

      .wave-is-hidden-margin-top {
        margin-top: 2.5rem;
        margin-bottom: 1.5rem;
      }

      .buttons {

      }

      .white-space-pre-line {
        white-space: pre-line;
      }
    `;

    static properties = {
        i18n: { type: Object },
        model: { type: Object },
        fieldset: { type: Object },
        selectedItem: { type: Number },
        fieldsetErrors: { type: Array },
        sections: { type: Object },
        config: { type: Object }
    }

    constructor () {
        super();

        this.selectedItem = -1;
        this.model = {};
        this.sections = {};
        this.fieldsetErrors = [];

    }


    scrollToBottom = () => {
        const element = document.getElementById("root");
        element.scrollTop = element.scrollHeight;
    }


    firstUpdated ( _changedProperties ) {
        super.firstUpdated(_changedProperties);

    }

    _notify ( text, theme ) {

        Notification.show(text, {
            position: 'top-center',
            theme: theme
        });

    }

    _validateFields () {


        /* DOCINFO: Remember to update this list when adding new one component */
        let fields = this.shadowRoot.querySelectorAll("vaadin-form-layout > vaadin-text-field,  vaadin-form-layout > field-date,  vaadin-form-layout > field-geo, vaadin-form-layout > vaadin-text-area ");

        fields.forEach(field => field.validate());

        if (this.fieldsetErrors.length > 0) {

            /* Convert fieldName to human-readable label */
            const namedFields = `\n\n${this.sections
                .flatMap(item => item.fieldset)
                .map(item => {
                    return { fieldName: item.fieldName, label: item.label }
                })
                .filter(item => this.fieldsetErrors.includes(item.fieldName))
                .map(item => `● ` + item.label + `\n`).join("")}`;

            this._notify(this.i18n.errorFillTheForm + namedFields, "error")
            return false;
        }
        return true;
    }


    resolve () {

        if (this._validateFields()) {

            this.dispatchEvent(
                new CustomEvent("form-resolved", {
                    detail: this.model,
                    bubbles: true,
                    composed: true,
                })
            );
        }
    }


    formValidate ( e ) {

        switch (e.detail.value) {
            case true:
                this.fieldsetErrors.push(e.target.name);
                break;
            case false:
                this.fieldsetErrors.splice(this.fieldsetErrors.indexOf(e.target.name), 1);
        }

        this.requestUpdate()

    }

    generateFieldset () {

        return html
            `
                ${repeat(this.sections, ( section, index ) => html`
                    <div style="${this.selectedItem !== index ? 'display: none' : 'display: block'}">
                        <vaadin-form-layout id="form-data-${Math.floor(Math.random() * 1000 + 1)}">
                            ${
                                    repeat(section.fieldset, ( fieldset, index ) =>
                                            choose(fieldset.type,
                                                    [
                                                        ['text', () => this.textField(fieldset, index)],
                                                        ['textArea', () => this.textArea(fieldset, index)],
                                                        ['date', () => this.fieldDate(fieldset, index)],
                                                        ['checkbox', () => html``],
                                                        ['radio', () => html``],
                                                        ['coordinates', () => this.textFieldGeo(fieldset, index)]
                                                    ], () => html``)
                                    )
                            }
                        </vaadin-form-layout>
                    </div>`
                )}
            `;

    }

    textFieldGeo = ( item, index ) => html`



        <field-geo theme="large"
                   .helperText="${item?.helperText}"
                   .id="${item.fieldName}"
                   .name="${item.fieldName}"
                   .minlength="${item.minlength}"
                   .maxlength="${item.maxlength}"
                   .label="${item.label}"
                   .geo="${this.model[item.fieldName]}"
                   .readonly="${item.readonly}"
                   @invalid-changed="${( e ) => this.formValidate(e)}"
                   @gps-changed="${( e ) => this.model[item.fieldName] = e.detail}"
                   .pattern="${item.rule}"
                   .required="${item.required}"
                   .helperText="${item?.helperText}"
                   colspan="${item.colspan}"
        >

        </field-geo>

    `;

    fieldDate = ( item, index ) => html`

        <field-date
                .id="${item.fieldName}"
                .helperText="${item?.helperText}"
                .name="${item.fieldName}"
                .minlength="${item.minlength}"
                .maxlength="${item.maxlength}"
                .label="${item.label}"
                .max="${formatISO(Date.now(), { representation: "date" })}"
                .value="${this.model[item.fieldName]}"
                .readonly="${item.readonly}"
                @invalid-changed="${( e ) => this.formValidate(e)}"
                @value-changed="${( e ) => this.model[item.fieldName] = e.detail}"
                .rule="${item.rule}"
                .required="${item.required}"
                colspan="${item.colspan}">

        </field-date>

    `;

    textField = ( item, index ) => html`
        <vaadin-text-field
                id="${item.fieldName}"
                .helperText="${item?.helperText}"
                .name="${item.fieldName}"
                .minlength="${item.minlength}"
                .maxlength="${item.maxlength}"
                .label="${item.label}"
                .value="${this.model[item.fieldName]}"
                error-message="${item.errorMessage}"
                .readonly="${item.readonly}"
                @change="${( e ) => this.formValidate(e)}"
                @invalid-changed="${( e ) => this.formValidate(e)}"
                @value-changed="${( e ) => this.model[item.fieldName] = e.detail.value}"
                .pattern="${item.rule}" ,
                .required="${item.required}"
                colspan="${item.colspan}">

        </vaadin-text-field>
    `;


    textArea = ( item, index ) => html
        `
            <vaadin-text-area
                    id="${item.fieldName}"
                    .name="${item.fieldName}"
                    .label="${item.label}"
                    .value="${this.model[item.fieldName]}"
                    .readonly="${item.readonly}"
                    .helperText="${item?.helperText}"
                    error-message="${item.errorMessage}"
                    @invalid-changed="${( e ) => this.formValidate(e)}"
                    @value-changed="${( e ) => this.model[item.fieldName] = e.detail.value}"
                    colspan="${item.colspan}"
                    .required="${item.required}"

            ></vaadin-text-area>
        `;

    generateSection () {

        if (this.sections.length === 1 && this.sections[0].name.length === 0) {
            this.selectedItem = 0;
            return html``;
        }


        return html`
            <vaadin-tabs @selected-changed="${( e ) => {
                this.selectedItem = e.detail.value
            }}">
                ${repeat(this.sections.map(section => section.name), ( item ) => item, ( item, index ) => html`
                    <vaadin-tab class="white-space-pre-line">${item}</vaadin-tab>`)}
            </vaadin-tabs>`;
    }


    render () {


        return html`

            <vaadin-horizontal-layout style="justify-content: center; height: 100%">
                <vaadin-vertical-layout style="height: 100%; width: 100%; " class="keep">
                    <vaadin-vertical-layout theme="" style="background-color: var(--lumo-contrast-0pct); width: 100%; "
                                            ?hidden="${!this.config.topVisible === true}">
                        <div style="width: 100%; background-color: #f3f4f5; ">
                            <div style="padding-left: var(--lumo-space-l); padding-right: var(--lumo-space-l);">
                                <H3>${this.i18n.heroTitle}</H3>
                            </div>
                        </div>
                        <svg style="width: 100%;" class=" ${!this.config.waveVisible === true ? 'wave-is-hidden' : ''}"
                             viewBox="0 0 786.45166 71.022777"
                             version="1.1"
                             id="svg4"
                             sodipodi:docname="wave.svg"

                             inkscape:version="1.1.2 (0a00cf5339, 2022-02-04)"
                             xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                             xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                             xmlns="http://www.w3.org/2000/svg"
                             xmlns:svg="http://www.w3.org/2000/svg">

                            <path
                                    fill="#f3f4f5"
                                    fill-opacity="1"
                                    d="M 0,25.763344 43.691761,35.773477 C 87.383519,45.890956 174.76705,65.750204 262.15056,70.124601 349.53409,74.33798 436.91761,63.066519 524.30114,50.104337 611.68466,37.303175 699.06818,22.811294 742.75994,15.753211 L 786.45168,8.5877811 V 0 H 742.75994 C 699.06818,0 611.68466,0 524.30114,0 436.91761,0 349.53409,0 262.15056,0 174.76705,0 87.383519,0 43.691761,0 H 0 Z"
                                    id="path2"
                                    style="stroke:none;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none"/>
                        </svg>
                    </vaadin-vertical-layout>

                    <div class="content  ${!this.config.waveVisible === true ? 'wave-is-hidden-margin-top' : ''} white-space-pre-line">
                        ${this.i18n.introText}
                    </div>

                    <div class="form">
                        <div class="section">
                            ${this.generateSection()}
                        </div>
                        <div class="items">
                            ${this.generateFieldset()}
                        </div>
                    </div>

                    <div style="margin-top: auto; width: 100%">
                        <vaadin-horizontal-layout theme="spacing padding" style="justify-content: start">
                            <vaadin-button theme="primary large" @click="${() => this.resolve()}" class="action-button">
                                ${this.i18n.btnNextText}
                            </vaadin-button>
                        </vaadin-horizontal-layout>
                    </div>
                </vaadin-vertical-layout>
            </vaadin-horizontal-layout>
        `;
    }
}

window.customElements.define('form-page', FormPage)
